import React from "react"

import Layout from "../components/layout"
import { charte } from "../components/charte"
import { Document, Page } from "react-pdf"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import MT1 from "../tips-pdf/November.pdf"
//import MT2 from "../tips-pdf/December.pdf"



const MT = (props) => {
  return ( <>
      <div align="center" style={{color: charte.secondary}}>{props.download.slice(9,-4)} 2019</div>
      <a href={props.download}><Document file={props.file}>
        <Page pageNumber={1} scale={0.5}/>
      </Document></a></>

  )
}

const  MonthlyTips = () => (
  <Layout page={"MonthlyTips"}>
    <h1 align="center" style={{color: charte.primary}}>Monthly Tips</h1>
    <div style={{height: "30px"}}/>
    <Container>
      <Row>
        <Col align="center"><MT file={MT1} download={'tips-pdf/November.pdf'}/></Col>
        {//<Col align="center" ><MT file={MT2} download={'tips-pdf/December.pdf'}/></Col>
        }
        <p/>
      </Row>
    </Container>
  </Layout>
)

export default MonthlyTips
